import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import imgAbout from "../assets/ESG_Banner.jpg";
import imgSide from "../assets/ilustração_frase.png";
import imgC1 from "../assets/icon-folha.png";
import imgC2 from "../assets/icon-circulo.png";
import imgC3 from "../assets/icon-coração.png";
import imgC4 from "../assets/icon-gov.png";
import pattern from "../assets/Separador_imgens.png";
import foto from "../assets/imagem_02.jpeg";
import background from "../assets/background-dobra.jpg";
import flor4 from "../assets/Estrela_4.png";
import lilponte from "../assets/Ponte.png";

function HomePage() {
  return (
    <>
      <Navbar />

      <section
        id="conheca"
        className="bg-lilas mt-10 h-1/2 flex items-center justify-around max-md:flex-col w-full overflow-clip"
      >
        <div className="flex items-center w-3/5 max-md:w-full">
          <img
            src={imgAbout}
            alt="imagem decorativa"
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col items-start w-2/5 h-1/2 ml-4 mr-10 max-sm:w-5/6 max-md:items-center max-md:mr-5">
          <p className="text-amarelo my-4 text-5xl max-sm:text-4xl max-sm:text-center">
            <strong>
              Uma escola de educação executiva
              <br /> em ESG
            </strong>
          </p>
          <p className="text-gray-50 text-lg font-extralight tracking-tight mb-8 mr-20 max-sm:text-base max-md:mr-0 lg:text-xl max-md:text-center">
            <strong>
              Você já considerou a importância de seu time assumir{" "}
              <strong className="text-xl font-bold">
                responsabilidades sociais, de diversidade, inclusão e de
                cuidados com o meio ambiente?
              </strong>{" "}
              Esses são temas cruciais para empresas que desejam se destacar no
              mercado atual, e é essencial que você esteja preparado para
              liderar seu time nessa direção.{" "}
            </strong>
          </p>
          <HashLink smooth to="/#ponte">
            <button
              type="button"
              className="p-2 mb-3 bg-laranja text-amarelo text-xl font-bold hover:-translate-y-1 hover:shadow-xl transition-all ease-linear rounded-xl"
            >
              Conheça a Ponte ESG
            </button>
          </HashLink>
        </div>
      </section>

      <div
        id="ponte"
        className="flex w-full h-full bg-repeat bg-cover"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="flex items-center w-full justify-around my-12 mx-20 max-md:mx-5 max-md:flex-wrap max-md:justify-center">
          <div className="basis-2/4 max-md:basis-full max-md:mr-0 p-5 mr-4">
            <img src={imgSide} alt="imagem decorativa" className="" />
          </div>
          <div className="pr-12 basis-2/4 ml-4 mr-20 max-md:mx-1 max-sm:pr-0 max-sm:p-0 max-md:mt-5 max-md:basis-full max-sm:text-center p-5 0">
            <p className="text-xl max-xl:text-base ml-2 max-md:leading-5 text-azul leading-6 font-semibold max-sm:text-center">
              É uma metafora poderosa para o futuro.{" "}
              <strong>
                {" "}
                Conectamos empresas com a sustentabilidade, equidade e
                governança.
              </strong>
              Nós entendemos que essas três áreas são fundamentais para
              construir um futuro melhor para todos.
              <br />
              <br />
              Somos especialistas em{" "}
              <strong>
                {" "}
                diversidade, inclusão, responsabilidades sociais e ambientais.
              </strong>
              Nós construímos uma ponte que liga as empresas a um futuro mais
              sustentável e equitativo. Acreditamos que é possível mudar o mundo
              dos negócios e estamos aqui para ajudar as empresas a fazerem a
              transição para um mundo mais consciente. Entender a importância do
              ESG, para
              <strong>
                {" "}
                criar soluções personalizadas que atendam às suas necessidades.{" "}
              </strong>
            </p>
            <h4 className="text-xl mt-10 max-sm:text-center max-lg:text-base bg-azul text-amarelo font-bold py-2 px-4 w-fit ">
              Junte seu time na construção do futuro.
            </h4>
          </div>
        </div>
      </div>

      <div
        className="h-48 bg-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(${pattern})` }}
      />

      <section
        id="cursos"
        className="text-center mt-10 mb-16 mx-20 max-sm:mx-10"
      >
        <h1 className="text-6xl font-semibold text-lilas underline decoration-verde decoration-4 mb-10">
          Cursos
        </h1>

        <div className="cursos-container flex justify-center items-center w-full h-1/2 max-[1100px]:flex-col max-lg:h-fit">
          <div className="flex my-5 max-sm:flex-col">
            <div className="curso-1 h-auto w-60 flex-1 mx-4 py-5 bg-azul flex items-center justify-center my-5">
              <div className="px-5 h-80 flex flex-col items-center justify-between relative">
                <img src={imgC1} alt="imagem decorativa" className=" w-20 " />
                <h2 className="normal-case font-semibold text-3xl mt-14 mb-14 text-white max-[390px]:text-2xl max-[390px]:mb-16">
                  ESG Starter
                </h2>
                <HashLink to="/cursos/intro-esg#conteudo">
                  <button className="absolute inset-x-0 -bottom-10 mx-auto w-fit bg-verde py-1 px-2 font-semibold text-3xl normal-case text-azul whitespace-nowrap hover:-translate-y-1 hover:shadow-xl transition-all ease-linear">
                    Saiba Mais
                  </button>
                </HashLink>
              </div>
            </div>

            <div className="curso-2 flex-1 mx-4 py-5 bg-lilas h-auto w-60 flex items-center justify-center my-5">
              <div className="px-5 h-80 flex flex-col items-center justify-between relative">
                <img src={imgC2} alt="imagem decorativa" className="w-28" />
                <h2 className="normal-case font-semibold mt-4 mb-4 text-3xl text-white max-[390px]:text-2xl">
                  DE&I na Gestão de Equipes
                </h2>
                <HashLink to="/cursos/intro-de&i#conteudo">
                  <button className="absolute inset-x-0 -bottom-10 mx-auto w-fit bg-amarelo py-1 px-2 font-semibold text-3xl normal-case text-lilas whitespace-nowrap hover:-translate-y-1 hover:shadow-xl transition-all ease-linear">
                    Saiba Mais
                  </button>
                </HashLink>
              </div>
            </div>
          </div>

          <div className="flex my-5 max-sm:flex-col">
            <div className="curso-3 flex-1 mx-4 py-5 bg-vermelhoclaro h-auto w-60 flex items-center justify-center my-5">
              <div className="px-5 h-80 flex flex-col items-center justify-between relative">
                <img src={imgC3} alt="imagem decorativa" className="w-28" />
                <h2 className="normal-case font-semibold text-3xl text-white max-[390px]:text-2xl">
                  Equidade de Gênero na Gestão de Equipes
                </h2>
                <HashLink to="/cursos/genero-esg#conteudo">
                  <button className="absolute inset-x-0 -bottom-10 mx-auto w-fit bg-azul py-1 px-2 font-semibold text-3xl normal-case text-amarelo whitespace-nowrap hover:-translate-y-1 hover:shadow-xl transition-all ease-linear">
                    Saiba Mais
                  </button>
                </HashLink>
              </div>
            </div>

            <div className="curso-4 flex-1 mx-4 py-5 bg-verde h-auto w-60 flex items-center justify-center my-5">
              <div className="px-5 h-80 flex flex-col items-center justify-between relative">
                <img src={imgC4} alt="imagem decorativa" className="w-28" />
                <h2 className="normal-case font-semibold text-3xl mt-4 mb-7 text-white max-[390px]:text-2xl max-[390px]:mb-10">
                  Fundamentos da Governança Ambiental
                </h2>
                <HashLink to="/cursos/impacto-ambiental#conteudo">
                  <button className="absolute inset-x-0 -bottom-10 mx-auto w-fit bg-vermelhoclaro py-1 px-2 font-semibold text-3xl normal-case text-verde whitespace-nowrap hover:-translate-y-1 hover:shadow-xl transition-all ease-linear">
                    Saiba Mais
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="jornada"
        className="bg-lilas flex w-full px-32 py-16 items-center max-lg:px-12 max-[835px]:flex-col max-md:px-10 max-md:py-10"
      >
        <div className="w-1/2 flex flex-col items-center max-md:w-full xl:scale-110">
          <h1 className="text-5xl text-white text-center font-semibold w-72 h-fit">
            Jornada Executiva ESG Starter
          </h1>
          <div className="flex items-center py-4 relative w-80 max-[360px]:scale-75">
            <img
              src={flor4}
              alt="imagem de uma estrela estilizada na cor verde, com o numero 4 dentro"
              className="w-40 z-20"
            />
            <h3 className="bg-azul rounded-r-xl w-48 h-fit text-white leading-tight text-xl pl-14 pr-4 py-1 absolute left-28 z-10">
              Cursos para você iniciar o futuro do seu negócio em ESG
            </h3>
          </div>
        </div>
        <div className="w-1/2 max-[835px]:my-5">
          <div className="w-fit flex flex-col items-center justify-center xl:scale-110 max-md:w-full max-[520px]:scale-90 max-[360px]:scale-75 transition-all ease-linear">
            <div className="flex my-1">
              <div className="p-10 relative">
                <img
                  src={lilponte}
                  alt="imagem decorativa"
                  className=" transform -scale-x-100 absolute w-12 bottom-1 right-1"
                />
              </div>
              <div className="bg-azul text-center text-white p-2 w-32 h-24 font-semibold leading-tight flex items-center justify-center">
                <h3>ESG Starter</h3>
              </div>
              <div className="p-10 relative">
                <img
                  src={lilponte}
                  alt="imagem decorativa"
                  className="absolute w-12 bottom-1 left-1"
                />
              </div>
            </div>
            <div className=" flex w-fit justify-center items-center">
              <div className="bg-verde text-center text-azul p-2 w-32 h-24 font-semibold leading-tight flex items-center justify-center">
                <h3>Fundamentos da Governança Ambiental</h3>
              </div>
              <div className="w-32 mx-1">
                <h3 className="w-32 uppercase text-white font-semibold text-xl text-center">
                  juntos
                </h3>
              </div>
              <div className="bg-transparent border border-white text-center text-white p-2 w-32 h-24 font-semibold leading-tight flex items-center justify-center">
                <h3>Equidade de Gênero na Gestão de Equipes</h3>
              </div>
            </div>
            <div className="flex my-1">
              <div className="p-10 relative">
                <img
                  src={lilponte}
                  alt="imagem decorativa"
                  className=" rotate-180 absolute w-12 top-1 right-1"
                />
              </div>
              <div className="bg-vermelhoclaro text-center text-white p-2 w-32 h-24 font-semibold leading-tight flex items-center justify-center">
                <h3>Questões de Gênero em ESG</h3>
              </div>
              <div className="p-10 relative">
                <img
                  src={lilponte}
                  alt="imagem decorativa"
                  className=" transform -scale-x-100 rotate-180 absolute w-12 top-1 left-1"
                />
              </div>
            </div>
            <button className="uppercase font-bold bg-white text-lilas p-2 rounded-full h-fit w-fit mt-8 text-2xl whitespace-nowrap max-sm:xl hover:scale-110 hover:shadow-md transition-all ease-in-out">
              quero esse combo
            </button>
            {/* <Link to="https://link.pagar.me/lSJxZLumaBn">
            </Link> */}
          </div>
        </div>
      </section>

      <section
        id="quem"
        className="bg-azul flex max-md:flex-wrap max-md:justify-center"
      >
        <div className="w-3/5 max-md:w-full overflow-clip">
          <img
            src={foto}
            alt="foto com mãos de várias pessoas uma em cima da outra"
            className=""
          />
        </div>
        <div className="mx-5 flex flex-col items-start justify-center py-10 pr-20 w-2/5 max-md:w-full max-sm:px-0 max-sm:mx-10 max-sm:justify-center max-sm:items-center max-sm:text-center">
          <h1 className="text-white text-5xl mb-8 font-semibold max-lg:text-3xl">
            Para quem são os treinamentos
            <br /> da Ponte ESG?
          </h1>
          <h2 className="text-white text-lg mb-8 font-semibold max-lg:text-base max-sm:mx-0 leading-tight">
            Os treinamentos oferecidos pela Ponte ESG são direcionados para
            empresas que buscam uma cultura organizacional mais sustentável e
            socialmente responsável, independentemente do porte. Destinados a um
            público amplo que inclui:
          </h2>

          <ul className="marker:text-verde list-disc text-white font-semibold text-xl pl-6 leading-tight max-lg:text-base max-sm:text-left max-sm:pl-3 max-sm:text-base">
            <li>C-Levels;</li>
            <li>Gestores de Recursos Humanos,</li>
            <li>Administradores e</li>
            <li>Heads de diversas áreas de negócios.</li>
          </ul>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default HomePage;
