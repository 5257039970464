import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CourseDetails from "../components/CourseDetails";

import { courses } from "../coursesData";

function SaibaMais() {
  // const { sobre, nivel, publico, ementa, orientadores, data, vagas, infoadd, investimento} = curso;
  const { curso } = useParams()
  // console.log('params', curso);
  const selectedCourse = courses.find((item) => item.path === curso);
  
  return (
    <div className="bg-gray-100">
      <Navbar />
      <CourseDetails dataToUse={selectedCourse} />
      <Footer />
    </div>
  );
}

export default SaibaMais