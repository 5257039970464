export const courses = [
  {
    paylink: 'https://link.pagar.me/lryBCBSBSh',
    path: 'intro-esg',
    bg: 'azul',
    titulo: 'ESG Starter',
    sobre: `O ESG, acrônimo que representa "Environmental, Social e Governance", tem ganhado enorme relevância na última 
    década. O surgimento dessa abordagem é reflexo das inúmeras mudanças que o mundo tem vivenciado. Aspectos econômicos,
    sociais, políticos e ambientais têm sido influenciados gradativamente pela mudança de compreensão e vivência das pessoas
    nessa nova realidade social mais dinâmica, tecnológica e diversa. As redes sociais impulsionaram a emergência de debates
    acerca de questões civilizatórias como diversidade, dignidade, justiça social e justiça climática. Os consumidores, em
    paralelo, também passaram a incorporar tais questões em seus comportamentos, o que tem levado o mercado a olhar suas
    práticas e posicionamentos em relação as questões ambientais, sociais e práticas de governança corporativa.`,
    ementa: [
      {
        title: 'Desenvolvimento Sustentável: um imperativo',
        excerpt: `Aqui iremos apresentar como surge a ideia de desenvolvimento
        sustentável num contexto de disrupção sistêmica e apresentar o ESG como
        um aspecto inovador para as empresas e gestores responderem às mudanças 
        de hábitos dos consumidores e novas exigências de investidores. Além disso, 
        vamos mostrar porque pensar ESG faz a diferença para as empresas e para o mercado.`,
        bullets: [
          'Histórico',
          'Conceito',
          'Dimensões',
          'Agenda 2030 e Objetivos do desenvolvimento sustentável',
          'Surgimento do ESG',
          'Finanças sustentáveis'
        ]
      },
      {
        title: 'Social: da porta para dentro e da porta para fora',
        excerpt: `O	comportamento	dos	stakeholders, especialmente dos consumidores,
        também se transformando em paralelo e absorvido muitas dessas questões relacionadas
        ao desenvolvimento sustentável. Hoje, portanto, apresentam forte impacto no consumo
        de produtos e serviços e, cada vez mais, as empresas precisam estar preparadas
        para entender e responder questões sociais dentro e fora de seus ecossistemas.`,
        bullets: [
          'Capitalismo de stakeholders',
          'Comportamento dos consumidores',
          'Diversidade, Equidade e Inclusão',
          'Ferramentas de gestão',
        ]
      },
      {
        title: 'Governança: meio, fim, forma e conteúdo',
        excerpt: `O objetivo é demonstrar como Governança Corporativa
        tem o papel de ser a liga das três letras do ESG. Na medida em 
        que um sistema de governança eficiente é capaz de propiciar um elevado 
        nível de análise e debate de temas estratégicos empresariais, a governança 
        é tanto o meio como o fim de uma jornada cultural voltada para o ESG.`,
        bullets: [
          'Conceito',
          'Princípios',
          'Capitalismo de stakeholders',
          'Comportamento dos consumidores',
        ]
      },
    ],
    tematicas: [
      'desenvolvimento sustentável',
      'finanças sustentáveis',
      'Social',
      'Governança',
    ],
    coffee: [
      'Encerramento do curso com Coffee Break e Networking',
    ],
    facilitadores: [
      {
        nome: 'Amanda Salvino',
        bio: `Cientista Política e Internacionalista. É pesquisadora associada no Instituto Ella Criações Educativas e da Coordenadoria de Estudos da Ásia (UFPE), onde desenvolve trabalhos nas áreas de Meio Ambiente, Desenvolvimento e Governança no Sul Global. Atuou nacional e internacionalmente com diversos projetos, organizações e tomadores de decisões desenvolvendo estratégias socioambientais para geração de impacto positivo. Atualmente presta consultoria e assessoria para empresas e organizações em questões relacionadas à ESG.`,
      },
      {
        nome: 'Rafael Lima',
        bio: `Advogado, sócio fundador do escritório R.Lima Advogados e da Pontéia Consultoria.
        MBA em Gestão de Organizações e Negócios de Impacto pelo Instituto Legado.
        Pós-graduado (lato sensu) em Direito Administrativo pela Universidade Estácio de Sá. 
        Pós-graduado (lato sensu) em Compliance e Integridade Corporativa pela PUC/MG. Graduado em Direito pela Universidade Católica de Pernambuco.
        Certificado em relatos de sustentabilidade pela GRI (Global Reporting Initiative).`,
      },
      {
        nome: 'Katharinny Bione',
        bio: `Mestre em Economia, pós-graduanda em Gestão de Projetos na Amazônia,  especialista em Gestão Empresarial e em Gestão para o Terceiro Setor, graduada em Administração de Empresas. Certificada PMD Pro®.
        PMO da Mandú Social e Coordenadora Financeira e Gestora de Projetos em Desenvolvimento Econômico da Rede Mondó. Avaliadora de negócios de Impacto do Inovativa Brasil. Professora de MBA e pós-graduação em renomadas instituições de ensino do país. Possui 9 anos de experiência na gestão de programas em responsabilidade socioambiental corporativa e 7 anos de experiência no mercado financeiro e governança corporativa.`,
      },
      {
        nome: 'Natasha Magalhães Zanardi',
        bio: `Formada em Serviço Social pela UNI FMU e pós graduada em Organização e Gestão de Políticas Públicas e Sociais. Especialista em Tecnologia Social com mais de 08 anos de atuação no mercado de Techs e startups, com foco na Governança orientada à sustentabilidade e inovação. A sua Trajetória em ESG teve início na College com um intercâmbio internacional voltado para Empreendedorismo social e transformações na sociedade do futuro, passando pelo curso em ESG oferecido pela B3  e especialista em ESG e Gestão pela USP EsalQ.`,
      }
    ],
    cargaHor: '12 horas',
    cargaMod: '4 horas',
    data: '07/07/2023 e 08/07/2023',
  },
  {
    paylink: 'https://link.pagar.me/lSkkOHrrr3 ',
    path: 'intro-de&i',
    bg: 'lilas',
    titulo: 'DE&I na gestão de equipes',
    sobre: `O curso abordará a ESG na perspectiva da valorização da diversidade, equidade e 
    inclusão de todas as pessoas no contexto corporativo. A perspectiva inclusiva deve ser consolidada 
    para que os estereótipos de raça, gênero, orientação sexual e origem não influenciem na contratação 
    de pessoas e nem se perpetuem no cotidiano do trabalho em equipe. É preciso compreensão das desigualdades 
    que atingem nosso país para construção de um ambiente seguro, equânime e inclusivo para todas as pessoas 
    e qualquer profissional. Assim, o curso partirá de conceitos-chave do ESG, abordando brevemente viés histórico 
    e focando na atualidade das relações de raça, gênero e origem para então apresentar casos nacionais e 
    internacionais de sucesso em ESG e proposições inclusivas que podem ser aplicadas em todos os setores`,
    ementa: [
      {
        title: 'Diversidade, Equidade e Inclusão',
        excerpt: `Este momento do curso será de introdução ao tema. Será abordado sobre a 
        história dos conceitos de diversidade, equidade e inclusão e a importância da perspectiva 
        da DE&I como um eixo basilar do ESG. É preciso compreensão das desigualdades que atingem 
        nosso país para construção de um processo de responsabilidade das empresas em edificar um 
        novo modelo cultural de organização, mais diverso, equânime e inclusivo para todas as pessoas.`,
        bullets: [
          'Histórico',
          'Conceito',
          'Relação com ESG',
        ]
      },
      {
        title: 'Impacto social a partir da inclusão da diversidade',
        excerpt: `Este módulo tem como objetivo apresentar conceitos importantes para entender 
        a necessidade de políticas de diversidade nas empresas. É preciso compreensão das desigualdades 
        que atingem nosso país para construção de um ambiente seguro e inclusivo para todo e qualquer profissional.`,
        bullets: [
          'Desigualdades históricas',
          'Reprodução das desigualdades no ambiente de trabalho',
          'Políticas de diversidade',
        ]
      },
      {
        title: 'Cases de sucesso',
        excerpt: `O último módulo do encontro será dedicado a trabalharmos , de 
        forma interativa, em cima de casos de sucesso de empresas que implementaram um 
        programa de diversidade. O objetivo é entender o que há de comum nessas políticas a 
        fim de proporcionar ferramentas reais para alcançarmos uma mudança de cultura nas organizações.`,
        bullets: [
          'Promoção da DE&I',
          'Cases',
          'Programas de diversidade',
          'Ferramentas',
        ]
      },
    ],
    tematicas: [
      'DE&I',
      'Impacto Social',
      'Cases de Sucesso',
    ],
    coffee: [
      'Encerramento do curso com Coffee Break, bate papo e autógrafos do livro "Educação antirracista e decolonial" de Deborah Monteiro',
    ],
    facilitadores: [
      {
        nome: 'Deborah Monteiro',
        bio: `Deborah Monteiro é cofundadora do Instituto Ella Criações Educativas. Palestrante, professora e consultora pela Ponte ESG. É mestra em Educação pela USP, com pesquisa voltada para relações raciais. Graduada em Letras pela PUC-SP. Já atuou como regente em cursos livres e de pós-graduação EAD e presenciais. Foi formadora de professores da Rede Municipal do Ensino de São Paulo. É autora do livro "Educação antirracista e decolonial no chão da escola" da editora Dialética. No Instituto Ella e na Ponte ESG, é uma das responsáveis pela elaboração, coordenação e acompanhamento dos cursos, sendo também regente em algum deles.`,
      },
      {
        nome: 'Ana Helena Passos',
        bio: `Ana Helena Ithamar Passos é cofundadora do Instituto Ella Criações Educativas, palestrante, educadora e consultora pela Ponte ESG. Advogada formada pela Universidade Católica de Pernambuco, mestra e doutora em Serviço Social, pela Pontifícia Universidade Católica do Rio de Janeiro (PUCRio). Pesquisadora na área de estudo das relações étnico-raciais, com ênfase no campo de conhecimento dos estudos críticos da branquitude e nos estudos da epistemologia feminista e gênero. Trabalhou com projetos de impacto social em áreas de proteção ambiental no Rio de Janeiro e em comunidades quilombolas em São Paulo. Foi professora universitária na Universidade Castelo Branco na zona leste de São Paulo e pesquisadora associada do Think Etnus.. Atua como professora de educação a distância em cursos de educação em direitos humanos. Atualmente, no Instituto Ella e na Ponte ESG, é responsável pela criação e execução de projetos e programas de Diversidade, Equidade e Inclusão tanto nas empresas como na elaboração de cursos.`,
      },
      {
        nome: 'Izabela Domingues',
        bio: `Izabela Domingues é PhD em Mídias Digitais e Cibercultura. Pós-doutora pelo Centro de Estudos em Consumo e Sociedade (Coppead/UFRJ). Sócia da Consumix, consultoria especialista em gestão de marcas e tendências de consumo, embarcada no Porto Digital, associada ABRADi, Softex e Assespro. Conveniada Sebraetec para Branding, Inovação e Design Service. Professora do Núcleo de Design e Comunicação e do Programa de Pós-Graduação em Comunicação da UFPE, também do curso IA para Negócios da CESAR School. Cofundadora e professora dos cursos Gestão de Negócios na Era Digital (CESAR School) e Líderes Digitais (Sebrae). Integra o conselho da Rede Latino-Americana de Estudos sobre Vigilância, Tecnologia e Sociedade (Lavits/Unicamp/CNPq). Autora dos livros Publicidade de Controle e Terrorismo de Marca, de capítulos de livros e artigos científicos publicados nacional e internacionalmente. Coautora dos livros Comunicação e Marketing Digital, Consumo de Ativismo e Transformação Digital: Uma Jornada Possível.`,
      },
    ],
    cargaHor: '12 horas',
    cargaMod: '4 horas',
    data: '21/07/2023 e 22/07/2023',
  },
  {
    paylink: 'https://link.pagar.me/lrkq48SrS3',
    path: 'genero-esg',
    bg: 'vermelhoclaro',
    titulo: 'Equidade de gênero na gestão de equipes',
    sobre: `A igualdade entre gêneros é um dos imperativos da Declaração Universal dos Direitos Humanos e 
    está presente nos textos constitucionais de muitos países, Um progresso significativo foi feito para 
    melhorar a igualdade de gênero e aumentar as oportunidades para as mulheres no mercado de trabalho. 
    No entanto, elas ainda enfrentam obstáculos como preconceito de gênero, desigualdade salarial e acesso 
    limitado a cargos de liderança.

    Nos últimos tempos, tem havido um crescente reconhecimento da importância da diversidade e inclusão no 
    local de trabalho, incluindo a diversidade de gênero. Muitas organizações estão promovendo ativamente a 
    igualdade de gênero e implementando políticas e iniciativas para apoiar o avanço na carreira das mulheres.
    
    Para avançar na equidade de gênero, as empresas e gestores devem somar promover ambientes de trabalho 
    inclusivos que valorizem a diversidade, ofereçam oportunidades iguais e apoiem a integração entre trabalho 
    e vida pessoal. Programas de orientação, iniciativas de desenvolvimento de liderança, acordos de trabalho 
    flexíveis e treinamento em diversidade são algumas das estratégias usadas para promover a igualdade de gênero
    e criar uma cultura de trabalho mais favorável.
    `,
    ementa: [
      {
        title: 'Questões de gênero',
        excerpt: `O módulo inicial propõe a reflexão do cotidiano sobre as desigualdades 
        vividas e experienciadas por mulheres Além disso, é proposta central a discussão sobre 
        a historicidade do conceito de gênero e o uso no campo do trabalho. Refletir sobre os 
        rumos da História das Mulheres e das Relações de Gênero no pensamento contemporâneo em uma 
        perspectiva inclusiva que aborde os principais desafios para a construção de políticas de 
        governança com equidade de gênero. `,
        bullets: [
          'Desigualdades históricas',
          'Conceito',
          'Jornadas de trabalho',
          'Desafios',
        ]
      },
      {
        title: 'Gênero e mercado de trabalho',
        excerpt: `O debate sobre diversidade no mercado de trabalho vem demonstrando o quanto é positivo para 
        organizações ter lideranças femininas e que para alcançarmos essa realidade é preciso enfrentar a histórica 
        desigualdade de gênero.  
        De acordo com PNAD (2019), mulheres são 52,8% da população disponível para o trabalho, mas representam menos 
        de 43% da população ocupada (formais e informais) e os homens ocupam 60,9% dos cargos de liderança, e as 
        mulheres 39,1%.
        Esse módulo apresenta essa realidade com o objetivo de construir um conjunto de alternativas mais responsáveis 
        com o combate a essa desigualdade e a edificação de políticas de equidade de gênero nas empresas. 
        `,
        bullets: [
          'Estatísticas sobre as mulheres no mercado de trabalho',
          'Reprodução das desigualdades e violências no ambiente de trabalho',
          'Políticas de equidade nas empresas',
        ]
      },
      {
        title: 'Cases de sucesso',
        excerpt: `O último módulo tem como base a metodologia do Arco Empreendedor do design e a interseccionalidade 
        como base conceitual da proposta, por entender que as estruturas do racismo e as opressões de gênero  e outros 
        sistemas discriminatórios criam desigualdades básicas que sedimentam as posições relativas de mulheres, raça, 
        etnia, classes e outras. 
        Essa reflexão é fundamental para pensar a relação entre práticas de governança, sustentabilidade e a performance 
        de organizações nos programas voltados à equidade de gênero. 
        `,
        bullets: [
          'Exemplos e métodos baseados no arco empreendedor',
          'Co-criação',
        ]
      },
    ],
    tematicas: [
      'Questões de gênero',
      'Mercado de Trabalho',
      'Cases de Sucesso',
    ],
    coffee: [
      'Encerramento do curso com Coffee Break e Networking',
    ],
    facilitadores: [
      {
        nome: 'Janaína Guimarães',
        bio: `Janaina Guimarães da Fonseca e Silva tem pós-doutorado pela Universidade da Paraíba, sendo doutora em História pela Universidade Federal de Pernambuco (2012), onde também fez mestrado (2007) e graduação(2004).  É professora adjunta da Universidade de Pernambuco, campus Mata Norte e  membro permanente do  PROFHISTÓRIA da UPE e do Programa de Pós-graduação em História da UFRPE, na Linha de ensino de História,.  Vice-coordenadora do GT Estudos de Gênero da Associação Nacional de Pesquisa em História (ANPUH), seção Pernambuco.  A pesquisadora possui experiência na área de Ensino e Pesquisa em História e Educação, atuando principalmente nos seguintes eixos: Politicas públicas educacionais para mulheres  a população LGBTQIAP+, Relações de Gênero, estudos interdisciplinares para uma Educação Antirracista e Mulheres Negras na educação de Pernambuco.`,
      },
      {
        nome: 'Ana Helena Passos',
        bio: `Ana Helena Ithamar Passos é cofundadora do Instituto Ella Criações Educativas, palestrante, educadora e consultora pela Ponte ESG. Advogada formada pela Universidade Católica de Pernambuco, mestra e doutora em Serviço Social, pela Pontifícia Universidade Católica do Rio de Janeiro (PUCRio). Pesquisadora na área de estudo das relações étnico-raciais, com ênfase no campo de conhecimento dos estudos críticos da branquitude e nos estudos da epistemologia feminista e gênero. Trabalhou com projetos de impacto social em áreas de proteção ambiental no Rio de Janeiro e em comunidades quilombolas em São Paulo. Foi professora universitária na Universidade Castelo Branco na zona leste de São Paulo e pesquisadora associada do Think Etnus.. Atua como professora de educação a distância em cursos de educação em direitos humanos. Atualmente, no Instituto Ella e na Ponte ESG, é responsável pela criação e execução de projetos e programas de Diversidade, Equidade e Inclusão tanto nas empresas como na elaboração de cursos.`,
      },
      {
        nome: 'Juliane Miranda',
        bio: `Empreendedora desde os 17 anos: fundadora da Trocando em Miúdos (2OO6- 2O19)) e da Soneto Tropical (2O19-2O21). Formada em Design pela UFPE, com parte cursada na Universidad de Valladolid (Espanha). Pós graduada em Gestão Empresarial pela FGV. Mestre em Design | Negócios Inovadores, pela CESAR SCHOOL. Doutoranda em Design pela UFPE. Atuou como curadora e designer para o projeto Design com Comunidade Quilombola junto a O Imaginário/UFPE (2O21). Mentoria e banca avaliadora do Pequenos Negócios, Grandes Mulheres junto ao Sebrae e a Verda (2O21).`,
      },
    ],
    cargaHor: '12 horas',
    cargaMod: '4 horas',
    data: '04/08/2023 e 05/08/2023',
  },
  {
    paylink: 'https://link.pagar.me/lHkwd8SrHn',
    path: 'impacto-ambiental',
    bg: 'verde',
    titulo: 'Fundamentos da governança ambiental',
    sobre: `Proteger o meio ambiente é crucial para a sustentabilidade a longo prazo das empresas e do planeta. 
    As mudanças climáticas, a escassez de recursos e a poluição representam riscos significativos para a economia
    global. Tomar medidas proativas para minimizar o impacto ambiental pode ajudar a mitigar esses riscos e 
    garantir a viabilidade dos negócios no futuro. Nesse sentido, o curso abordará aspectos fundamentais para a 
    compreensão das importâncias de mecanismos para alcançar um equilíbrio entre proteção ambiental, equidade social 
    e desenvolvimento econômico. `,
    ementa: [
      {
        title: 'Questões introdutórias sobre Meio Ambiente e Sustentabilidade',
        excerpt: `Este momento do curso será de introdução ao tema. Será abordado sobre a história dos conceitos 
        de sustentabilidade, crise climática e relação com ESG.
        Também demonstrará a relação entre justiça climática, desenvolvimento econômico e ESG.
        `,
        bullets: [
          'Histórico',
          'Crise climática',
          'Justiça ambiental e social',
          'Sustentabilidade empresarial',
        ]
      },
      {
        title: 'Práticas de gestão empresarial sustentável',
        excerpt: `Este módulo tem como objetivo apontar quais as práticas que podem ser adotadas por empresas e 
        gestores para iniciar o caminho rumo a uma governança ambiental sustentável. Tendo como ponto de partida a 
        necessidade de pensar as questões legais, a gestão de stakeholders, o engajamento dos colaboradores e novas 
        tecnologias como aspectos integrados dentro de um plano estratégico.  `,
        bullets: [
          'Conceitos relacionados',
          'Gestão de stakeholders',
          'Engajamento dos colaboradores',
          'Adoção de novas tecnologias',
          'Gestão econômica',
        ]
      },
      {
        title: 'Estratégia e Melhores Práticas de Mercado',
        excerpt: `O último módulo do encontro será dedicado a trabalharmos, de forma interativa, em cima de casos 
        de sucesso de empresas que implementaram programas voltados para a sustentabilidade. O objetivo é entender 
        como dar início ao  desenho de um projeto estratégico e os primeiros passos de implementação, a fim de 
        proporcionar ferramentas reais para alcançarmos uma mudança de cultura nas organizações.`,
        bullets: [
          'Desenho de projeto estratégico',
          'Implementação',
          'Cases',
        ]
      },
      
    ],
    tematicas: [
      'Introdução',
      'Práticas de gestão ',
      'Cases de Sucesso',
    ],
    coffee: [
      'Encerramento do curso com Coffee Break',
    ],
    facilitadores: [
      {
        nome: 'Cristiana Duarte',
        bio: `Possui graduação em Bacharelado em Geografia (2007), mestrado em Geografia (2009) e doutorado em Geografia pela Universidade Federal de Pernambuco (2015). Já foi professora do quadro efetivo do curso de Geografia da Universidade de Pernambuco - Campus Garanhuns e Coordenadora Setorial de Extensão dessa mesma universidade. Atualmente é Professora Adjunta do Departamento de Geografia da Universidade Federal de Pernambuco. Tem experiência na área de Geotecnologias e Geociências, com ênfase em Geomorfologia e Climatologia, atuando principalmente nos seguintes temas: Gestão de risco a desastres naturais, Sistema de Informações Geográficas (SIG) e Sensoriamento Remoto aplicados a análise da paisagem e modelagem ambiental.`,
      },
      {
        nome: 'Carlos Pompeu',
        bio: `Doutor em Educação Tecnológica pela UFPE. Mestre em Educação pela Universidade Presbiteriana Mackenzie e Administrador pela UPE. Tem formações em competitividade e negócios pela IESE Business School de Barcelona e pelo INDES/BID nos EUA. Foi gerente de Negócios Educacionais e professor do Mestrado em Design da CESAR School. Possui mais de 20 anos de experiência na área de marketing, modelagem de negócios de educação e gestão de projetos educacionais. Criou startups e projetos de cooperação internacional no Brasil e em Portugal. Atuou como docente na pós-graduação em Marketing, Estratégia e Inovação na Universidade Anhembi Morumbi (SP), SENAC (SP) e na Universidade de Pernambuco (UPE).`,
      },
     /*  {
        nome: 'xxx',
        bio: `xxx`,
      }, */
    ],
    cargaHor: '12 horas',
    cargaMod: '4 horas',
    data: '11/08/2023 e 12/08/2023',
  }
];