import React from "react";
// import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Accordion from "./Accordion";

import bg from "../assets/background-dobra.jpg";
import folha from "../assets/icon-folha.png";
import estrela from "../assets/estrelagrande.png";

function CourseDetails(props) {
  const dataToUse = props.dataToUse;

  return (
    <>
      <div
        className="bg-cover bg-fixed"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div
          id="conteudo"
          className=" mx-32 mt-10 py-10 max-md:mx-10" /* style={{backgroundColor: dataToUse.bg}} */
        >
          <h1 className="bg-gradient-to-r from-lilas to-lilas via-azul text-white uppercase text-center text-2xl p-10">
            <strong>{dataToUse.titulo}</strong>
          </h1>
          <section className="my-10 max-sm:text-sm">
            <div className="flex items-center my-4">
              <Icon
                icon="material-symbols:school-rounded"
                width={32}
                height={32}
                color="whitesmoke"
                /* className="p-1 bg-black text-gray-100" */ className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">O curso</h2>
            </div>
            <p className="my-4 ml-14 max-sm:ml-0">{dataToUse.sobre}</p>
          </section>
          <section className="my-10">
            <div className="my-4 flex">
              <Icon
                icon="mingcute:target-fill"
                width={32}
                height={32}
                color="whitesmoke"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">Público</h2>
            </div>
            <p className="ml-14 max-sm:ml-0 max-sm:text-sm">
              C-Levels, Gestores de Recursos Humanos, Administradores e Heads de
              diversas áreas de negócios.
            </p>
          </section>
          <section className="my-10 ">
            <div className=" my-4 flex">
              <Icon
                icon="mingcute:target-fill"
                width={32}
                height={32}
                color="whitesmoke"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">Conteúdo programático</h2>
            </div>
            <div className="ml-14 max-sm:ml-0">
              {dataToUse.ementa.map((item) => (
                <Accordion ementa={item} key={item.title} />
              ))}
            </div>
          </section>
          <section className="my-10">
            <div className="my-4 flex">
              <Icon
                icon="mdi:user-group"
                width={32}
                height={32}
                color="white"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">Facilitadores</h2>
            </div>
            {dataToUse.facilitadores.map((item) => {
              return (
                <div key={item.nome} className="my-4 ml-14 max-sm:ml-0">
                  <h3 className="font-semibold">{item.nome}</h3>
                  <p className="text-sm max-sm:text-xs">{item.bio}</p>
                </div>
              );
            })}
          </section>
          <section className="my-10">
            <div className="flex my-4">
              <Icon
                icon="gridicons:scheduled"
                width={32}
                height={32}
                color="white"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">Save the date</h2>
            </div>
            <div className="ml-14 max-sm:ml-0">
              <p className="font-semibold">data</p>
              <p className="text-sm mb-2">{dataToUse.data}</p>
              <p className="font-semibold">local</p>
              <p className="text-sm mb-2">
                Station Work | Rua do Bom Jesus, 125 - Recife Antigo
              </p>
              {/*                 <p className="font-semibold">carga horária por módulo</p>
                <p className="text-sm mb-2">{dataToUse.cargaMod}</p> */}
              <p className="font-semibold">certificado</p>
              <p className="text-sm mb-2">{dataToUse.cargaHor}</p>
            </div>
          </section>
          <section className="my-10 max-sm:text-sm">
            <div className="my-4 flex">
              <Icon
                icon="material-symbols:question-mark-rounded"
                width={32}
                height={32}
                color="white"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">
                Informações Adicionais
              </h2>
            </div>
            <p className="ml-14 max-sm:ml-0">
              - As datas dos cursos ou treinamentos estão sujeitas a remarcação
              em até 48hr antes da data estipulada em caso de não conter a
              quantidade mínima de quórum para fechamento de turma - O aluno só
              terá direito ao certificado, após concluir 75% do curso. Em casos
              de falta por motivos de saúde, o aluno precisará entrar em contato
              com a central e apresentar atestado médico com até 24h de
              antecedência às aulas.
            </p>
          </section>

          <section
            id="investimento"
            className=" py-8 w-full bg-lilas flex items-center justify-evenly relative z-0 overflow-clip max-[920px]:flex-col"
          >
            <img
              src={folha}
              alt="ícone de uma folha"
              className="absolute z-0 -top-4 -left-24"
            />
            <h2 className="text-3xl text-lilas font-bold bg-gray-50 text w-fit h-fit py-1 px-3 border border-gray-700 rounded-xl z-10  max-[350px]:text-xl">
              Investimento
            </h2>
            <img
              src={estrela}
              alt="ícone de uma estrela"
              className="absolute z-0 w-96 h-96 mr-10"
            />
            <img
              src={folha}
              alt="ícone de uma folha"
              className="absolute z-0 -right-10 -bottom-12"
            />

            <div className="flex flex-col z-20 justify-center items-center mx-0 text-white uppercase leading-none max-[920px]:my-4">
              <p className="flex items-center leading-none">
                <span className="text-sm font-medium mr-1">De:</span>
                <span className="line-through decoration-laranja decoration-2 text-xl font-medium">
                  R$ 1.249,00
                </span>
              </p>
              <div className="flex items-center justify-center my-2">
                <div className="flex flex-col leading-none font-medium text-sm">
                  <span>Por:</span>
                  <span className="normal-case italic">5x de</span>
                </div>
                <span className="text-3xl flex font-semibold mx-1 leading-none">
                  R$
                </span>
                <strong className="text-7xl  max-[340px]:text-4xl">149</strong>
                <span className="text-4xl font-semibold">,80</span>
              </div>
            </div>

            <div className="flex items-center z-20">
              <button className="flex items-center hover:-translate-y-1 transition-all ease-in-out bg-laranja text-neutral-50 text-3xl p-2 uppercase font-semibold whitespace-nowrap my-2 rounded-xl max-[350px]:text-base">
                Compre agora!
              </button>
              {/* <Link to={dataToUse.paylink} target="_blank" rel="noopener noreferrer">
                </Link> */}
            </div>
          </section>
          {/* <section id="investimento-backup" className="my-10 ">
              <div className="my-4 flex">
                <Icon icon="material-symbols:sell" width={32} height={32} color="white" className={`p-1 bg-${dataToUse.bg}`} />
                <h2 className="text-2xl font-bold ml-5">Investimento</h2>
              </div>
              <p className="ml-14 max-sm:ml-0 flex flex-col  justify-center items-center">
                <s>
                  De: R$1249,00
                </s>
                <b className="font-bold text-xl">
                <br />
                Por: R$749,00
                </b>
              </p>

              <div className="my-4 flex flex-row  justify-center items-center max-sm:flex-col">
                <div className="flex items-center">
                  <Link to={dataToUse.paylink} target="_blank" rel="noopener noreferrer">
                    <button className="flex items-center hover:-translate-y-1 transition-all ease-in-out">
                      <Icon icon="mingcute:currency-dollar-2-fill" width={32} height={32} className="p-1 bg-azul text-gray-100 rounded-l-lg" />
                      <p className="bg-azul text-neutral-50 p-1 uppercase whitespace-nowrap my-2 rounded-r-lg">Compre agora!</p>
                    </button>
                  </Link>
                </div>
              </div>
            </section> */}
          <section className="my-10 max-sm:text-sm">
            <div className="my-4 flex">
              <Icon
                icon="fluent:important-24-filled"
                width={32}
                height={32}
                color="white"
                className={`p-1 bg-${dataToUse.bg}`}
              />
              <h2 className="text-2xl font-bold ml-5">Atenção</h2>
            </div>
            <p className="ml-14 max-sm:ml-0">
              Devolução de investimento só será realizada em casos previstos em
              lei. Ressaltamos que a simples impossibilidade de comparecimento
              ao curso após a realização da inscrição não caracteriza
              justificativa para reembolso ou emissão de certificado.
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
