import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Icon } from "@iconify/react";
import Button from "./Button";
import logoP from "../assets/logo-ponte.svg";

function Navbar() {
  const [toggle, setToggle] = useState(false);
  


  return (
    <nav className="bg-white z-50 shadow-sm px-10 py-1 lg:h-12 max-md:h-fit max-sm:pt-2 max-sm:justify-between max-sm:px-5 flex items-center justify-between max-lg:flex-wrap fixed top-0 w-full">
      <Link to="/"><div className="flex items-center w-40 transition-all ease-in-out">
        <img src={logoP} alt="Logo Ponte ESG" className="w-32 hover:w-36 transition-all ease-in-out" />
      </div></Link>
      <div className="sm:hidden cursor-pointer hover:animate-bounce" onClick={() => setToggle(!toggle)}>
        <Icon icon="icon-park-outline:hamburger-button" width={40} height={40} color="gray" />
      </div>
      {/* renderização menu hamburger (mobile) */}
        { toggle ? <div className="flex items-center justify-center">
          <HashLink smooth to={'/#ponte'}><Button text="A Ponte" /></HashLink>
          <HashLink smooth to={'/#cursos'}><Button text="Cursos" /></HashLink>
          <HashLink smooth to={'/#quem'}><Button text="Público" /></HashLink>
          <HashLink smooth to={'/#jornada'}><button type="button" className="px-2 py-1 mx-4 bg-verde font-semibold whitespace-nowrap hover:text-lilas hover:text-lg hover:font-bold transition-all ease-in-out">Inscreva-se</button></HashLink>
          
        </div> : <></>}
      {/* renderização padrão */}
      <div className="basis-2/4 flex items-center justify-end max-sm:hidden">
        <HashLink smooth to={'/#ponte'}><Button text="A Ponte" /></HashLink>
        <HashLink smooth to={'/#cursos'}><Button text="Cursos" /></HashLink>
        <HashLink smooth to={'/#quem'}><Button text="Público" /></HashLink>
        <HashLink smooth to={'/#jornada'}><button type="button" className="px-2 py-1 mx-4 bg-verde font-semibold whitespace-nowrap hover:text-lilas hover:text-lg hover:font-bold transition-all ease-in-out">Inscreva-se</button></HashLink>
      </div>
    </nav>
  )
}

export default Navbar;
