import React from "react";

function Button({text}) {
  return (
    <button type="button" className="h-fit w-20 mx-2 max-sm:mx-1.5 my-1 max-sm:py-3 text-black text-base font-semibold whitespace-nowrap max-sm:w-fit max-sm:px-1 hover:text-lilas hover:font-bold hover:text-lg hover:min-w-fit max-sm:hover:text-lg transition-all ease-in-out">
      {text}
    </button>
  );
}

export default Button;
