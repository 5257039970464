import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import ErrorPage from './pages/ErrorPage';
import SaibaMais from './pages/SaibaMais';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  /* {
    path: "/cursos", // aqui pode ser uma pagina de detalhes sobre todos os cursos? 
    element: <SaibaMais />,
    errorElement: <ErrorPage />,
  }, */
  {
    path: "/cursos/:curso", // aqui o ideal seria que o endereço fosse saibamais-"nome-do-curso" - verificar como fazer isso 
    element: <SaibaMais />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
