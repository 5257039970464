import React, { useState } from 'react';
import { Icon } from '@iconify/react';

const Accordion = ({ ementa }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="py-4">
      <div className="flex justify-between items-center cursor-pointer border-b-2 border-gray-300" onClick={() => setIsActive(!isActive)}>
        <h1 className="text-lg font-semibold my-2">{ementa.title}</h1>
        {/* <Icon icon="mdi:arrow-down-drop-circle" className="hover:rotate-180 transition-all ease-linear" /> */}
        <div className="hover:rotate-180 active:rotate-180 transition-all ease-linear text-black">
          {isActive ? <Icon icon="material-symbols:arrow-drop-up-rounded" width={32} height={32} /> : <Icon icon='material-symbols:arrow-drop-down-rounded' width={32} height={32} />}
        </div>
      </div>
      {isActive && <div className="transition-all ease-linear flex flex-col items-start">
        <p className="m-2 max-sm:text-sm">{ementa.excerpt}</p>
        <ul className="list-disc w-max ml-7 my-2 max-sm:text-sm">{ementa.bullets.map((element) => <li key={element}>{element}</li>)}</ul>
      </div>}
      
    </div>
  );
};

export default Accordion;