import React from "react";
import { Icon } from "@iconify/react";
// import logo from "../assets/Logo_GrupoAlternativa (1).png";

// [#87C9E8]

function Footer() {
  return (
    <div className="bg-[#131735] h-8 text-gray-100/50 text-sm py-1 max-sm:px-5 flex items-center justify-center overflow-clip max-[360px]:flex-wrap max-[360px]:h-fit">
      <a href="https://ogrupoberlim.com.br/">
        <h1 className="whitespace-nowrap flex items-center justify-center max-[360px]:flex-wrap">Grupo Berlim <Icon icon="mdi:copyright" className="mx-1"/> 2023 | <span className="ml-1">Todos os direitos reservados</span></h1>
      </a>
    </div>
  )
}

export default Footer;
